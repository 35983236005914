.App{
/*  background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: calc(6px + 2vmin);
  color: #282c34;
}

.App-header {
  width: 100%;
}

.App-banner {
  width: 100%;
  height: 60vmin;
  background-image: url("cropped-slider1-1024x615.png");
  background-attachment: local;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-bannerAligner {
  display: inline-block;
  height: 100%;
  vertical-align: bottom;
  width: 0px;
  background: red;
}

.App-bannerText {
  padding-left: 10vmin;
  padding-bottom: 3vmin;
  color: white;
  display: inline-block;
}

.App-socialwrapper {
  margin-left: 5vmin;
}

.App-social {
  height: 5vh;
  padding-top: 3vh;
  padding-left: 3vmin;
  padding-right: 3vmax;
}

.App-logo {
  height: 10vmax;
  margin-top: 5vmax;
  pointer-events: none;
}

.App-copyright {
  font-size: calc(4px + 2vmin);
  pointer-events: none;
}

.App-body {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.App-menu {
  width: 40vmin;
  min-width: 10vmin;
  padding-left: 3vmax;
  padding-right: 3vmax;
  padding-top: 10vmin;
  display: flex;
  font-size: calc(6px + 2vmin);
  color: #282c34;
}

.App-content {
/*  background-color: #282c34;*/
  width: 140vmax;
  padding-left: 10vmin;
  padding-top: 10vmin;
  padding-right: 10vmin;
  display: flex;
  flex-direction: column;
  align-items: flex-start;;
  font-size: calc(6px + 2vmin);
  color: #282c34;
}

.App-footer {
  padding-top: 20vmin;
  padding-left: 5vmin;
}

.App-link {
  color: #61dafb;
}
